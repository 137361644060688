/* General container setup */
.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    background-color: var(--bg-shade);  /* Light background consistent with your theme */
    min-height: 100vh;
    box-sizing: border-box;
}

/* Hero Section */
.hero {
    text-align: center;
    margin-bottom: 50px;
}

.hero-title {
    font-size: 56px;
    font-weight: 700;
    color: var(--heading-color);
}

.hero-quote {
    font-size: 24px;
    color: var(--darkblue);
    margin-top: 20px;
}

/* About Section */
.about-container {
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-title {
    font-size: 36px;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.about-text {
    font-size: 18px;
    color: var(--darkblue);
    line-height: 1.6;
    margin-bottom: 30px;
}

.features-list {
    list-style: none;
    padding: 0;
    font-size: 18px;
    color: var(--darkblue);
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
}

.features-list li {
    margin-bottom: 15px;
}

.features-list li::before {
    content: '✓';
    color: var(--primary);
    font-weight: bold;
    margin-right: 10px;
}

/* Video Demo Section */
.demo-section {
    margin: 50px 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.demo-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.video-container {
    width: 80%;
}

/* Waitlist Section */
.waitlist-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
}

.waitlist-subtitle {
    font-size: 36px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.waitlist-text {
    font-size: 18px;
    color: var(--darkblue);
    margin-bottom: 40px;
}

.waitlist-form {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 20px;
}

.waitlist-label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.waitlist-input {
    padding: 16px;
    border-radius: 8px;
    border: 1.33px solid var(--primary);
    background-color: var(--white);
    font-size: 16px;
    color: var(--black);
}

.waitlist-submit-btn {
    padding: 14px 32px;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.waitlist-submit-btn:hover {
    background-color: var(--darkblue);
    color: var(--white);
}

/* Responsive Design */

/* Screens below 1200px (Tablet size) */
@media only screen and (max-width: 1200px) {
    .hero-title {
        font-size: 40px;
        line-height: 48px;
    }

    .hero-quote {
        font-size: 20px;
        line-height: 28px;
    }

    .about-title {
        font-size: 30px;
    }

    .about-text,
    .waitlist-text,
    .features-list li {
        font-size: 16px;
    }

    .demo-section {
        padding: 20px;
    }

    .waitlist-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .video-container {
        height: 400px; /* Slightly reduce height for smaller screens */
    }

    .waitlist-subtitle {
        font-size: 30px;
    }

    .waitlist-input {
        padding: 12px;
    }

    .waitlist-submit-btn {
        padding: 12px 24px;
        font-size: 14px;
    }
}

/* Screens below 768px (Mobile size) */
@media only screen and (max-width: 768px) {
    .hero-title {
        font-size: 30px;
        line-height: 36px;
    }

    .hero-quote {
        font-size: 18px;
        line-height: 24px;
    }

    .about-container,
    .waitlist-section {
        padding: 20px;
        margin: 20px 0;
        width: 80%;
    }

    .about-title,
    .waitlist-subtitle {
        font-size: 24px;
    }

    .about-text,
    .waitlist-text,
    .features-list li {
        font-size: 14px;
    }

    .video-container {
        height: 300px; /* Further reduce height for mobile screens */
    }

    .waitlist-input {
        padding: 10px;
    }

    .waitlist-submit-btn {
        padding: 10px 20px;
        font-size: 12px;
    }
}

/* Screens below 480px (Small mobile) */
@media only screen and (max-width: 480px) {
    .hero-title {
        font-size: 24px;
        line-height: 30px;
    }

    .hero-quote {
        font-size: 16px;
        line-height: 22px;
    }

    .about-title,
    .waitlist-subtitle {
        font-size: 20px;
    }

    .about-text,
    .waitlist-text,
    .features-list li {
        font-size: 12px;
    }

    .video-container {
        height: 200px; /* Minimized height for very small screens */
    }

    .waitlist-input {
        padding: 8px;
    }

    .waitlist-submit-btn {
        padding: 8px 16px;
        font-size: 10px;
    }
}