/* General container setup */
.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    background-color: var(--bg-shade);
    min-height: 100vh;
    box-sizing: border-box;
}

/* Hero Section */
.hero {
    text-align: center;
    margin-bottom: 50px;
}

.hero-title {
    font-size: 56px;
    font-weight: 700;
    color: var(--heading-color);
}

.hero-quote {
    font-size: 24px;
    color: var(--darkblue);
    margin-top: 20px;
}

/* Waitlist Section */
.waitlist-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
    margin-bottom: 40px;
}

.waitlist-subtitle {
    font-size: 36px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.waitlist-text {
    font-size: 18px;
    color: var(--darkblue);
    margin-bottom: 40px;
}

.waitlist-form {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 20px;
}

.waitlist-label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.waitlist-input {
    padding: 16px;
    border-radius: 8px;
    border: 1.33px solid var(--primary);
    background-color: var(--white);
    font-size: 16px;
    color: var(--black);
}

.waitlist-submit-btn {
    padding: 14px 32px;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.waitlist-submit-btn:hover {
    background-color: var(--darkblue);
    color: var(--white);
}

/* Features Section */
.features-section {
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
    margin-top: 40px;
}

.features-title {
    font-size: 28px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
    padding: 0;
    width: 100%;
}

.feature-card {
    background-color: var(--bg-shade);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    font-size: 40px;
    color: var(--primary);
    margin-bottom: 15px;
}

.feature-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 10px;
    text-align: center;
}

.feature-description {
    font-size: 16px;
    color: var(--darkblue);
    text-align: center;
}


/* Value Section */
.value-section {
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
    margin-top: 40px;
}

.value-title {
    font-size: 28px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.value-text {
    font-size: 18px;
    color: var(--darkblue);
    margin-bottom: 20px;
}

.value-list {
    list-style: none;
    padding: 0;
    font-size: 18px;
    color: var(--darkblue);
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
}

.value-list li {
    margin-bottom: 15px;
}

/* FAQ Section */
.faq-section {
    padding: 40px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    margin-top: 40px;
    text-align: left;
    /* Aligns content to the left within the section */
}

.faq-title {
    font-size: 28px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
    text-align: center;
    /* Keep title centered for visual appeal */
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 700px;
    margin: 0 auto;
}

.faq-item {
    background-color: var(--bg-shade);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
    /* Aligns text to the left inside each box */
}

.faq-question {
    font-size: 18px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 10px;
}

.faq-answer {
    font-size: 16px;
    color: var(--darkblue);
    margin: 0;
    /* Removes any extra margin for a clean look */
}


/* Responsive Design */
@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.25rem;
    }

    .lambda-icon {
        width: 6rem;
        height: 6rem;
    }

    .glow-effect {
        width: 9rem;
        height: 9rem;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 2rem;
    }

    .hero-subtitle {
        font-size: 1rem;
    }

    .feature-card {
        padding: 1.5rem;
    }
}