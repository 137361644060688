body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: var(--bg-shade);
    color: var(--text-color);
}

/* Blog Page Starts */
.blog-page {
    padding: 50px;
    margin-top: 3rem;
    background-color: var(--bg-shade);
}

.blog-page-title {
    font-size: 48px;
    color: var(--heading-color);
    text-align: center;
    margin-bottom: 40px;
}

.blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.blog-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-card-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-card-content {
    padding: 20px;
}

.blog-card-title {
    font-size: 24px;
    color: var(--heading-color);
    margin-bottom: 15px;
}

.blog-card-date {
    font-size: 16px;
    color: var(--darkblue);
    margin-bottom: 10px;
}

.blog-card-excerpt {
    font-size: 16px;
    color: var(--darkblue);
    margin-bottom: 20px;
}

.read-more {
    font-size: 16px;
    color: var(--primary);
    text-decoration: none;
    font-weight: 600;
}

/* Blog Page Ends */

/* Blog Post Page Starts */
.blog-post {
    max-width: 900px;
    margin: 0 auto;
    padding: 50px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.blog-post-cover {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

.blog-post-content {
    padding: 30px;
}

.blog-post-title {
    font-size: 48px;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.blog-post-meta {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

.blog-post-author {
    font-size: 18px;
    color: var(--darkblue);
}

.blog-post-date {
    font-size: 16px;
    color: var(--darkblue);
}

.blog-post-tags {
    display: flex;
    gap: 10px;
}

.blog-post-tag {
    font-size: 14px;
    background-color: var(--primary);
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
}

.blog-post-body {
    font-size: 18px;
    color: var(--darkblue);
    line-height: 1.6;
}

/* Blog Post Page Ends */

/* Responsive Design */
@media only screen and (max-width: 1200px) {

    .blog-page-title,
    .blog-post-title {
        font-size: 36px;
    }

    .blog-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .blog-post-cover {
        height: 300px;
    }

    .blog-post-content {
        padding: 20px;
    }

    .blog-post-body {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .blog-post-cover {
        height: 250px;
    }

    .blog-post-content {
        padding: 15px;
    }

    .blog-post-title {
        font-size: 28px;
    }

    .blog-post-body {
        font-size: 14px;
    }
}

/* Inline code */
.blog-post-content code {
    background-color: #f5f2f0;
    /* Light background for inline code */
    color: #d63384;
    /* Custom text color for inline code */
    padding: 0.2em 0.4em;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
}

/* Block code */
.blog-post-content pre {
    background-color: #282c34;
    /* Dark background for code blocks */
    color: var(--darkblue);
    /* Text color for code */
    padding: 15px;
    border-radius: 8px;
    overflow-x: auto;
    /* Allow horizontal scrolling */
}

.blog-post-content pre code {
    color: var(--bg-shade);
    /* Inherit color from pre for block code */
    background-color: #282c34;
    /* Remove background color from code */
}

/* Paragraph spacing for Markdown content */
.blog-post-content p {
    margin-bottom: 20px;
    line-height: 1.8;
}

.blog-post-content img {
    max-width: 80%;
    display: block;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}