.thank-you-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: var(--bg-shade);
}

.thank-you-content {
  max-width: 800px;
  background: var(--white);
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  text-align: center;
}

.thank-you-content h1 {
  color: var(--heading-color);
  margin-bottom: 2rem;
}

.message-section {
  margin: 3rem 0;
}

.main-message {
  font-size: 1.25rem;
  color: var(--darkblue);
  margin-bottom: 3rem;
  line-height: 1.6;
}

.next-steps {
  text-align: left;
  margin: 3rem 0;
  padding: 2rem;
  background: var(--bg-shade);
  border-radius: 1rem;
}

.next-steps h2 {
  color: var(--heading-color);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.next-steps ul {
  list-style: none;
  padding: 0;
}

.next-steps li {
  display: flex;
  align-items: flex-start;
  margin: 1.5rem 0;
  color: var(--darkblue);
  line-height: 1.6;
}

.step-number {
  background: var(--primary);
  color: var(--white);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  flex-shrink: 0;
  font-weight: 600;
}

.preparation {
  text-align: left;
  margin: 3rem 0;
}

.preparation h2 {
  color: var(--heading-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.preparation p {
  color: var(--darkblue);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.blog-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blog-link {
  display: block;
  padding: 1rem;
  background: var(--bg-shade);
  border-radius: 0.5rem;
  color: var(--primary);
  text-decoration: none;
  transition: all 0.3s ease;
}

.blog-link:hover {
  background: var(--primary);
  color: var(--white);
  transform: translateX(5px);
}

.contact-info {
  margin: 3rem 0;
  padding-top: 2rem;
  border-top: 1px solid var(--bg-shade);
}

.contact-info p {
  color: var(--darkblue);
  line-height: 1.6;
}

.email-link {
  color: var(--primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: var(--heading-color);
}

.home-button {
  margin-top: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .thank-you-content {
    padding: 2rem;
  }

  .thank-you-content h1 {
    font-size: 2rem;
  }

  .next-steps,
  .preparation {
    padding: 1.5rem;
  }

  .blog-links {
    gap: 0.75rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.thank-you-content {
  animation: fadeIn 0.6s ease-out forwards;
} 