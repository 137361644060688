.docs-page {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 2rem;
  max-width: auto;
  min-height: calc(100vh - 60px);
  background-color: var(--bg-color);
  scroll-behavior: smooth;
  margin-top: -2.6rem;
}

/* Navigation Sidebar */
.docs-nav {
  height: calc(100vh - 60px);
  width: 250px;
  overflow-y: auto;
  padding: 15px 20px;
  background: var(--white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
}

/* Webkit scrollbar styles */
.docs-nav::-webkit-scrollbar {
  width: 6px;
}

.docs-nav::-webkit-scrollbar-track {
  background: var(--bg-shade);
  border-radius: 3px;
}

.docs-nav::-webkit-scrollbar-thumb {
  background-color: var(--bg-shade);
  border-radius: 3px;
}

.nav-header {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.nav-header h3 {
  margin: 0;
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links>li {
  margin: 0.75rem 0;
}

.nav-link-header {
  cursor: pointer;
  padding: 0.75rem 1rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--bg-shade);
}

.nav-link-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--darkblue);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  /* margin-bottom: 3rem; */
  margin-top: 2rem;
}

.nav-links-sub {
  list-style: none;
  background-color: var(--bg-shade);
  padding: 0;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-links-sub.expanded {
  max-height: 500px;
  opacity: 1;
  padding: 0.5rem 0;
}

.nav-link-sub {
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--darkblue);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.8;
  transition: all 0.2s ease;
}

.nav-link-sub:hover {
  color: var(--primary);
  opacity: 1;
  background-color: var(--white);
  cursor: pointer;
}

.nav-link-sub.active {
  color: var(--primary);
  opacity: 1;
  font-weight: 500;
  background-color: var(--white);
}

.expand-icon {
  font-size: 10px;
  transition: transform 0.3s ease;
  color: var(--darkblue);
  opacity: 0.5;
  display: inline-block;
  margin-left: 8px;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.nav-link-main:hover {
  color: var(--primary);
  cursor: pointer;
}

.nav-link-main:hover .expand-icon {
  opacity: 1;
}

.nav-link-main.active {
  color: var(--primary);
  font-weight: 600;
}


/* Main Content */
.docs-content {
  width: 100%;
}

.markdown-heading {
  margin: 20px 0 10px;
  color: #0056a3;
}

.markdown-paragraph {
  margin: 10px 0;
  line-height: 1.6;
}

.markdown-list {
  margin: 10px 0 20px;
  padding-left: 20px;
}

.markdown-list-item {
  margin-bottom: 5px;
}

.inline-code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  font-family: monospace;
  border-radius: 3px;
}

code {
  background-color: #f9f9f9;
  display: block;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
}

/* Table Styling */
.docs-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 2rem 0;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.docs-content table th,
.docs-content table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--darkblue);
  line-height: 1.5;
}

.docs-content table th {
  background-color: var(--bg-shade);
  font-weight: 600;
}

.docs-content table tr:last-child td {
  border-bottom: none;
}

.docs-content table tr:nth-child(even) {
  background-color: var(--bg-shade-light);
}

.docs-content table tr:hover {
  background-color: var(--bg-shade-hover);
}

.docs-section {
  margin-bottom: 4rem;
}

.section-content {
  color: var(--darkblue);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

/* Content Components */
.personal-note {
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: 15px;
  line-height: 24px;
  color: var(--darkblue);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.insight-box {
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: 14px;
  line-height: 21px;
}

.docs-content {
  margin-left: 3rem;
  max-width: calc(100% - 250px);
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: var(--darkblue);
  font-size: 16px;
  line-height: 1.8;
}

.resource-box {
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: 14px;
  line-height: 21px;
}

.resource-box.highlight {
  border: 2px solid var(--primary);
}

.topic-grid,
.service-grid,
.tool-grid,
.creator-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 32px;
  margin: 1.5rem 0;
}

.topic-card,
.service-card,
.tool-card,
.creator-card {
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 24px;
  transition: all 0.3s ease;
}

.topic-card:hover,
.service-card:hover,
.tool-card:hover,
.creator-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
}

.learning-path ol {
  counter-reset: item;
  list-style-type: none;
  padding: 0;
}

.learning-path li {
  counter-increment: item;
  margin-bottom: 1.5rem;
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 24px;
  font-size: 14px;
  line-height: 21px;
}

.learning-path li h4::before {
  content: counter(item) ". ";
  color: var(--primary);
  font-weight: 600;
}

.contact-box {
  background-color: var(--bg-shade);
  border-radius: 10.6px;
  padding: 24px;
  margin-top: 3rem;
  text-align: center;
  border: 2px solid var(--primary);
}

.contact-box h3 {
  color: var(--heading-color);
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 27px;
}

/* Links */
.docs-section a {
  color: var(--primary);
  text-decoration: none;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 14px;
}

.docs-section a:hover {
  color: var(--darkblue);
}

/* Lists */
.docs-section ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.docs-section li {
  margin: 0.5rem 0;
  color: var(--darkblue);
  font-size: 14px;
  line-height: 21px;
}

/* Responsive Design */
@media only screen and (max-width: 1800px) {
  .docs-section h1 {
    font-size: 38px;
    line-height: 46px;
  }

  .docs-section h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 1200px) {
  .docs-page {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .docs-nav {
    position: relative;
    height: auto;
    margin-bottom: 2rem;
  }

  .docs-section:not(:first-child) {
    padding: 1.5rem;
  }

  .docs-section h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .docs-section h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .topic-grid,
  .service-grid,
  .tool-grid,
  .creator-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .personal-note,
  .insight-box,
  .resource-box {
    padding: 1.25rem;
  }
}

/* Subsections */
.subsection {
  margin: 3rem 0;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.subsection h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 1.5rem 0 1rem;
}

/* Command Groups */
.command-groups {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 1.5rem 0;
}

.command-group {
  background-color: var(--bg-shade);
  border-radius: 8px;
  padding: 1.5rem;
}

.command-group code {
  background-color: var(--white);
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
  font-size: 13px;
  color: var(--primary);
}

/* Best Practices Grid */
.best-practices-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 1.5rem 0;
}

.practice-card {
  background-color: var(--bg-shade);
  border-radius: 8px;
  padding: 1.5rem;
}

/* Management Content */
.management-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 1.5rem 0;
}

/* Troubleshooting Content */
.troubleshooting-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 1.5rem 0;
}

.troubleshooting-card {
  background-color: var(--bg-shade);
  border-radius: 8px;
  padding: 1.5rem;
}

/* Responsive adjustments */
@media only screen and (max-width: 768px) {

  .command-groups,
  .best-practices-grid,
  .management-content,
  .troubleshooting-content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .subsection {
    margin: 2rem 0;
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.section-header:hover {
  background-color: var(--bg-shade);
}

.expand-icon {
  font-size: 12px;
  transition: transform 0.3s ease;
  color: var(--darkblue);
  margin-left: 8px;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

/* Navigation styles for collapsible menu */
.nav-link-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links-sub {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-links-sub.expanded {
  max-height: none;
  opacity: 1;
  margin-top: 0.5rem;
}

/* Update heading styles */
.docs-section h1 {
  color: var(--heading-color);
  font-size: 42px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 1.5rem;
}

.docs-section h2 {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  margin: 1rem 0 1.5rem;
}

.docs-section h3 {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 1.5rem 0 1rem;
}

.docs-section h4 {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 1rem 0;
}

.action-prompt {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}