/* Thank You Page Starts */
.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Full screen height */
    background-color: var(--bg-shade);
    /* Light background to match site */
    text-align: center;
}

.thank-you-content {
    padding: 50px;
    background-color: var(--white);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
}

.thank-you-title {
    font-size: 100px;
    font-weight: 700;
    color: var(--primary);
    /* Your primary brand color */
    margin-bottom: 20px;
}

.thank-you-subtitle {
    font-size: 36px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.thank-you-text {
    font-size: 18px;
    color: var(--darkblue);
    margin-bottom: 40px;
}

/* Thank You Page Ends */